export default {
    methods: {
        formatPrice(price) {
            let val = (price / 1).toFixed(0).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getProductPrice(product) {
            let price = '0'
            if (product.descuento === '0%' || product.descuento === 0 || product.descuento === '0' || product.descuento == null) {
                return this.formatPrice(product.precio_venta);
            }
            if (product.relationships?.product?.skus && product.relationships.product.skus[0]?.prices) {
                let listPrice = product.relationships.product.skus[0].prices.find(p => p.price_list_id === 3)
                price = this.formatPrice(listPrice.price)
            }

            return price
        },
        getProductPriceFromOrder(product) {
            let price = '0'
            if (product.sku.product.versions[0].descuento === '0%' || product.sku.product.versions[0].descuento === 0 || product.sku.product.versions[0].descuento === '0' || product.sku.product.versions[0].descuento == null) {
                return this.formatPrice(product.on_sale_price)
            }
            if (product.sku.prices) {
                let listPrice = product.sku.prices.find(p => p.price_list_id === 3)
                price = this.formatPrice(listPrice.price)
            }

            return price
        }
    }
}
